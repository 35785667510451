<template>
  <div class="preview-box">
    <div class="preview-box-container">
      <div class="preview-box-title">
        <h2>Actions</h2>
      </div>
      <div
        class="preview-box-content"
        v-if="transaction"
      >
        <dl>
          <dt>Project Key</dt>
          <dd class="text-style codes">{{transaction.project.key}}</dd>
        </dl>
        <dl v-if="transaction.status && transaction.status.label">
          <dt>Status</dt>
          <dd class="text-style codes">{{transaction.status.label}}</dd>
        </dl>
        <dl>
          <dt>Notes</dt>
          <dd class="text-style codes">{{transaction.notes}}</dd>
        </dl>
      </div>
      <hr>
      <!-- <div
        class="preview-box-tools"
      >
        <ConfirmView
          actionButtonText="Cancel Transaction"
          :action="actionCancel"
          @on-action-done="onActionDone"
        />
        <br/>
        <ConfirmView
          actionButtonText="Approve Transaction"
          :action="actionApproveInvoice"
          @on-action-done="onActionDone"
        />
        <br>
        <ConfirmView
          actionButtonText="Add Notes"
          title="Notes"
          :action="actionPrivateNotes"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <textarea
              rows="5"
              v-model="actionPrivateNotes.model"
            />
          </template>
        </ConfirmView>
        <br/>
        <ConfirmView
          actionButtonText="Send Transaction Email"
          :action="actionSendInvoice"
          @on-action-done="onActionDone"
        />
        <br/>
      </div> -->
    </div>
  </div>
</template>
<script>
import {
  toRef,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import {
  TransactionReport,
  AdminAction,
} from '@/store/modules/admin';

// import ConfirmView from '@/components/admin/ConfirmView.vue';

export default {
  props: {
    transaction: Object,
  },

  components: {
    // ConfirmView,
  },

  setup(props) {
    const store = useStore();
    const transaction = toRef(props, 'transaction');
    const transactionKey = transaction.value.key;

    const actionCancel = ref(new AdminAction(
      () => TransactionReport.action(transactionKey, { action: 'cancel_transaction' }),
    ));

    const actionApproveInvoice = ref(new AdminAction(
      () => TransactionReport.action(transactionKey, { action: 'approve_transaction' }),
    ));

    const actionSendInvoice = ref(new AdminAction(
      () => TransactionReport.action(transactionKey, { action: 'send_transaction_email' }),
    ));

    const actionPrivateNotes = ref(new AdminAction(
      () => TransactionReport.action(transactionKey,
        { action: 'add_private_notes', private_notes: actionPrivateNotes.value.model }),
      null,
      { model: transaction.value.private_notes || '' },
    ));

    const onTransactionUpdate = (updatedInvoice) => {
      store.commit('admin/updatePreviewTransaction', { invoice: updatedInvoice });
    };

    const onActionDone = (context, updatedTransaction) => {
      onTransactionUpdate(updatedTransaction);
    };

    return {
      actionCancel,
      actionPrivateNotes,
      actionApproveInvoice,
      actionSendInvoice,
      onActionDone,
    };
  },
};
</script>
