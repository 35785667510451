<template>
  <QuickPreviewView
    v-if="previewTransaction && previewTransaction.key"
  >
    {{report.items}}
    <template v-slot:left>
      <ul class="item-box">
        <template  v-if="!$route.query.showProjectInfo && !$route.query.search">
          <li
            v-for="transaction in report.items"
            :key="transaction.key"
            @click="showPreviewTransaction(transaction)"
            :class="{active: transaction.key === previewTransaction.key}"
          >
            {{transaction.project.name}}
          </li>
        </template>
        <template v-else-if="$route.query.search">
          <li
            v-for="transaction in report.items"
            :key="transaction.key"
            @click="showPreviewTransaction(transaction)"
            :class="{active: transaction.transaction_id === previewTransaction.key}"
          >
            {{transaction.project_name}}
          </li>
        </template>
        <template v-else>
          <li
            v-for="transaction in report.items"
            :key="transaction.key"
            @click="showPreviewTransaction(transaction, true)"
            :class="{active: transaction.key === previewTransaction.key}"
          >
          {{transaction.project.name}}
          </li>
        </template>
      </ul>
    </template>
    <template v-slot:title>
      <h1 v-if="!$route.query.showProjectInfo">
        {{formatCurrency(previewTransaction.amount, previewTransaction.currency, true)}}
        - {{previewTransaction.project.name}}</h1>
      <h1 v-else>{{previewTransaction.project.name}}</h1>
      <div class="tools" v-if="$route.query.showProjectInfo">
        <router-link
          class="btn"
          :to="{name: 'ProjectPage', params: {projectKey: previewTransaction.project.key}}"
          >Open</router-link>
        <button
          @click="hidePreviewTransaction"
          class="btn"
          >X</button>
      </div>
      <div class="tools" v-else>
        <router-link
          class="btn"
          :to="{
              name: 'transaction',
              params: { transactionkey: previewTransaction.key, projectKey: previewTransaction.project.key } }"
          >Open</router-link>
        <button
          @click="hidePreviewTransaction"
          class="btn"
        >X</button>
      </div>
    </template>
    <template v-slot:content>
      <TransactionPreview
        :transactionKey="previewTransaction.key"
        :info="previewTransaction"
      />
    </template>
  </QuickPreviewView>
</template>
<script>
import moment from 'moment';
import { ref, onMounted, watch } from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  FilterableItem,
  Filterables,
  TransactionReport,
  ProjectList,
} from '@/store/modules/admin';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { currencyMethodsMixin } from '@/mixin/currency';
import TransactionPreview from '@/components/admin/TransactionPreview.vue';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';

export default {
  mixins: [currencyMethodsMixin],
  components: {
    TransactionPreview,
    QuickPreviewView,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = ref(new RequestState());
    const report = ref([]);
    let month = ref({}) || route.query.month;
    let year = ref({}) || route.query.year;
    const currentDay = moment();
    const prevMonth = ref(null);
    const nextMonth = ref(null);
    const previewTransaction = ref({});
    const showPaidItem = ref(
      new FilterableItem({
        name: 'Show Paid Items',
        number: 0,
        isCheckbox: true,
        selected: true,
        item: [],
      }),
    );
    const showUnpaidItem = ref(
      new FilterableItem({
        name: 'Show Unpaid Items',
        number: 0,
        isCheckbox: true,
        selected: false,
        item: [],
      }),
    );
    const showVirtualMoney = ref(
      new FilterableItem({
        name: 'Show Virtual Money Items',
        number: 0,
        isCheckbox: true,
        selected: false,
        item: [],
      }),
    );
    const filterables = ref(new Filterables({
      items: [
        showPaidItem.value,
        showUnpaidItem.value,
        showVirtualMoney.value,
      ],
    }));

    const showPreviewTransaction = (transaction, val) => {
      let showProjectInfo;
      route.query = showProjectInfo;
      route.query.showProjectInfo = val;
      previewTransaction.value = transaction;
      if (route && route.params && route.params.type) {
        // eslint-disable-next-line max-len
        router.push({
          name: 'adminQuickTransactionPreview',
          params: {
            type: route.params.type,
            key: transaction.key,
          },
          query: {
            year,
            month,
          },
        });
      } else if (route.query.search) {
        router.push({
          name: 'adminTransactionPreview',
          params: {
            key: transaction.transaction_id,
          },
          query: {
            search: route.query.search,
          },
        });
      } else {
        router.push({
          name: 'adminQuickTransactionPreview',
          params: {
            type: 'paidTransactions',
            key: transaction.key,
          },
          query: {
            year,
            month,
          },
        });
      }
    };

    const showPreviewTransactionUsingKey = (key) => {
      TransactionReport.fetchTransaction(key).then((response) => {
        showPreviewTransaction(response);
      });
    };

    const hidePreviewTransaction = () => {
      previewTransaction.value = {};
      router.push({
        name: 'transactionList',
      });
      store.commit('admin/updatePreviewTransaction', { transaction: null });
      return false;
    };

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      if (route.query.search) {
        return ProjectList.searchQuery(route.query.search, 'transaction').then((list) => {
          report.value.items = list.data.data.docs;
        });
      }
      if (route.query.month && route.query.year) {
        year = route.query.year;
        month = route.query.month;
      } else {
        year = currentDay.year();
        month = currentDay.format('MM');
      }
      return TransactionReport.fetch(year, month).then((_report) => {
        report.value.items = _report.items.items;
        if (route.params.type === 'paidTransactions') {
          report.value.items = report.value.items.filter((x) => (x.payment_status.isPaymentSuccess
        || x.payment_status.isPaymentNotRequired) && x.kind === 'money');
        } else if (route.params.type === 'unpaidTransactions') {
          report.value.items = report.value.items.filter((x) => (x.payment_status.isPaymentFailed
        || x.payment_status.isWaitingForPayment) && x.kind === 'money');
        } else if (route.params.type === 'virtualMoney') {
          report.value.items = report.value.items.filter((x) => x.kind !== 'money');
        } else if (route.params.type === 'unpaidWithVirtual') {
          report.value.items = report.value.items.filter((x) => x.payment_status.isPaymentFailed
        || x.payment_status.isWaitingForPayment);
        } else if (route.params.type === 'paidWithVirtual') {
          report.value.items = report.value.items.filter((x) => x.payment_status.isPaymentSuccess
        || x.payment_status.isPaymentNotRequired);
        } else if (route.params.type === 'null') {
          report.value.items = null;
        }
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const onTransactionClick = (transaction) => {
      showPreviewTransactionUsingKey(transaction.key);
    };

    const fetchTransaction = () => {
      if (route && route.params && route.params.key) {
        TransactionReport.fetchTransaction(route.params.key).then((response) => {
          previewTransaction.value = response;
        });
      }
    };

    watch(() => route.params.key, () => {
      if (route.params.key) {
        fetchData();
        fetchTransaction();
      }
    });

    onMounted(() => {
      if (route.query.search || route.params.type) {
        fetchData();
        fetchTransaction();
      }
    });

    return {
      state,
      report,
      currentDay,
      prevMonth,
      nextMonth,
      filterables,
      previewTransaction,
      onTransactionClick,
      showPreviewTransactionUsingKey,
      showPreviewTransaction,
      hidePreviewTransaction,
      year,
      month,
    };
  },
};
</script>
