/* eslint-disable import/prefer-default-export */
import { CurrencyObjs } from '../store/modules/order';

export const currencyMethodsMixin = {
  methods: {
    formatCurrency(amount, curency, round, decimalRoundOff, maxFractionDigits) {
      if (curency) {
        curency = curency.toUpperCase();
      }
      if (typeof curency !== 'object' && curency !== null) {
        curency = CurrencyObjs[curency].value;
      }
      if (typeof amount === 'string' || amount instanceof String) {
        amount = parseInt(amount, 10);
      }

      if (round) {
        amount = Math.round(amount);
      }
      if (decimalRoundOff) {
        amount = Math.round(amount * 100) / 100;
      }

      if (this.toLocaleStringSupportsOptions) {
        const options = {
          style: 'currency',
          currency: curency.key,
          minimumFractionDigits: decimalRoundOff ? 2 : 0,
          maximumFractionDigits: decimalRoundOff ? (maxFractionDigits || 2) : (maxFractionDigits || 4),
        };
        return amount.toLocaleString(undefined, options);
      }
      return curency.symbol + amount;
    },
    formatNumber(number, round) {
      if (typeof amount === 'string' || number instanceof String) {
        number = parseInt(number, 10);
      }

      if (round) {
        number = Math.round(number);
      }

      if (this.toLocaleStringSupportsOptions) {
        const options = {
          minimumFractionDigits: 0,
        };
        return number.toLocaleString(undefined, options);
      }
      return number;
    },
    /* eslint-disable operator-linebreak */
    toLocaleStringSupportsOptions() {
      return !!(
        typeof Intl !== 'undefined' &&
        Intl &&
        typeof Intl.NumberFormat !== 'undefined'
      );
    },
  },
};
