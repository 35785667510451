<template>
  <div class="admin-invoce-preview" v-if="!$route.query.showProjectInfo">
    <rz-request-state :state="state" />
    <div
      v-if="state.status.isLoaded"
    >
      <div class="preview-boxes">
        <div class="preview-box">
          <div class="admin-escape-theme">
            <div class="invoice">
              <TransactionView
                :invoiceInfo="{}"
                :receiptInfo="transaction"
              />
            </div>
          </div>
        </div>
        <TransactionPreviewActions
          :transaction="transaction"
        />
      </div>
    </div>
  </div>
  <QuickPreviewView
    v-if="previewProject && previewProject.key && $route.query.showProjectInfo"
    hideLeft="true"
    class="nofixed"
  >
    <template v-slot:content>
      <ProjectPreview
        :projectKey="previewProject.key"
      />
    </template>
  </QuickPreviewView>
</template>
<script>
import {
  ref,
  onMounted,
  watch,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  TransactionReport,
  ProjectList,
} from '@/store/modules/admin';

import ProjectPreview from '@/components/admin/ProjectPreview.vue';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';
import TransactionView from '@/components/admin/TransactionView.vue';
import TransactionPreviewActions from './TransactionPreviewActions.vue';

export default {
  props: {
    transactionKey: String,
    info: Object,
  },

  components: {
    TransactionView,
    TransactionPreviewActions,
    QuickPreviewView,
    ProjectPreview,
  },

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const state = ref(new RequestState());

    const previewProject = ref({});
    const projectKey = ref(route.params.key);

    const fetchData = () => {
      ProjectList.fetchProject(props.info.project.key).then((response) => {
        previewProject.value = response;
      });
    };

    onMounted(() => {
      fetchData();
    });

    watch(() => route.params, () => {
      projectKey.value = route.params.key;
      fetchData();
    });

    const fetchTransaction = () => {
      state.value.status = RequestStateStatus.Loading;
      TransactionReport.fetchTransaction(props.transactionKey).then((transactionResposne) => {
        store.commit('admin/updatePreviewTransaction', { transaction: transactionResposne });
      }).then(() => {
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    watch(() => props.transactionKey, () => {
      fetchTransaction();
      fetchData();
    });

    onMounted(() => {
      fetchTransaction();
    });

    return {
      state,
      transaction: computed(() => store.state.admin.previewTransaction),
      previewProject,
    };
  },
};
</script>
